
import { Section } from 'fsxa-api'
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IPosition } from '../../shared/general/interfaces/IPosition'
import { TPositionKey } from '../../shared/general/types/TPositionKey'
import { globalLabelAsString } from '../../shared/general/services/StoreService'

interface ITabData {
  key : TPositionKey
  iconName : string
  titleLabel : string
  content : Section[]
}

@Component({
  name: 'LeadGeneration',
  components: {
    BaseTab: () => import('../base/tab/BaseTab.vue'),
    BaseTabElement: () => import('../base/tab/BaseTabElement.vue'),
    BaseHeadline: () => import('../base/BaseHeadline.vue'),
    LeadGenerationContent: () => import('./LeadGenerationContent.vue'),
  },
})
export default class LeadGeneration extends Vue {
  @Prop() headline ?: string

  @Prop() contactData! : Section[]

  @Prop() formData! : Section[]

  @Prop() locationData! : Section[]

  @Prop({ default: [] }) positions! : IPosition[]

  private get tabData () : ITabData[] {
    return this.positions.map((position) => this.convertPositionKeyIntoTabData(position.key))
  }

  private convertPositionKeyIntoTabData (positionKey : TPositionKey) : ITabData {
    switch (positionKey) {
      case 'contact': return {
        key: positionKey,
        iconName: 'phone',
        titleLabel: globalLabelAsString('contact_tab_label'),
        content: this.contactData,
      }
      case 'form': return {
        key: positionKey,
        iconName: 'envelope',
        titleLabel: globalLabelAsString('form_tab_label'),
        content: this.formData,
      }
      case 'location': return {
        key: positionKey,
        iconName: 'location-dot',
        titleLabel: globalLabelAsString('location_tab_label'),
        content: this.locationData,
      }
      default: {
        /* Ensure that Typescript will report missing switch cases at compile time */
        const invalidKey : never = positionKey
        throw new Error(`Invalid position key: ${invalidKey}`)
      }
    }
  }
}
